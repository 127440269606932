<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">用户管理</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="昵称">
					<a-input v-model="query.name"></a-input>
				</a-form-model-item>
				<a-form-model-item label="微信ID">
					<a-input v-model="query.openid"></a-input>
				</a-form-model-item>
				<a-form-model-item label="手机号">
					<a-input v-model="query.phone"></a-input>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="phone" slot-scope="text, record">
				<a-space>
					{{ record.phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') }}
				</a-space>
			</span>
			<span slot="rent_count" slot-scope="text, record">
				<a @click="toOrder(record)">{{ record.rent_count }}次</a>
			</span>
		</a-table>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			customer_id: null,
			pagetotal: 0,
			checkValue: [],
			checkOptions: [
				{
					label: '使用中',
					value: 1
				},
				{
					label: '已完成',
					value: 2
				},
				{
					label: '已退款',
					value: 3
				}
			],
			columns: [
				{
					title: '序号',
					customRender: (text, record, index) => index + 1
				},
				{
					title: '昵称',
					dataIndex: 'name'
				},
				{
					title: '微信ID',
					dataIndex: 'openid'
				},
				{
					title: '电话',
					scopedSlots: {
						customRender: 'phone',
					},
				},
				{
					title: '最近一次使用',
					dataIndex: 'last_use_time'
				},
				{
					title: '租用（次）',
					scopedSlots: {
						customRender: 'rent_count'
					}
				},
				{
					title: '消费（元）',
					dataIndex: 'all_pay'
				},
				{
					title: '退款（元）',
					dataIndex: 'all_refund'
				}
			],
			data: [],
			selectedRowKeys: []
		}
	},
	created() {
		this.getList()
	},
	methods: {
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		toOrder(item) {
			if (item.rent_count > 0) {
				this.$router.push({
					path: '/orderList',
					query: {
						cid: item.customer_id
					}
				})
			} else {
				this.$message.warn("暂未产生订单")
			}
		},
		getList() {
			this.$post(domain + '/admin/v1/customer/customerList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
